import React from 'react'

export const Home = () => {
  return (
    <div>
      <h1>Welcome to Math Flash Cards!</h1>
      <p>
        I created this game to help my children learn their math facts!  
      </p>  
    </div>
  )
}
