import { useEffect, useState } from "react";



const useQuestionGenerator = (operator, lowNumber = 1, highNumber = 12) => {
  
  const [questions, setQuestions] = useState([]);

  useEffect(() => {

    function shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = array[i];
          array[i] = array[j];
          array[j] = temp;
      }
    }

    function createMultiplicationQuestion(num1, num2){
      return {
        question: num1 + " * " + num2,
        answer: num1 * num2
      }
    }

    function createDivisionQuestion(num1, num2){
      const total = num1 * num2;
      return {
        question: total + "/" + num1,
        answer: num2 
      }
    }

    function generateQuestions(){
      const questions = [];
      for(var x = lowNumber; x <= highNumber; x++){
          for(var i = lowNumber; i <= highNumber; i++){
            if(operator === "*"){
              questions.push(createMultiplicationQuestion(x,i));
            }else if(operator === "/"){
              questions.push(createDivisionQuestion(x,i));
            }
          }
      }
      return questions
    }

    const randomQuestions = generateQuestions();
    shuffleArray(randomQuestions)
    setQuestions(randomQuestions.slice(0,10));


    
  }, [operator]);

  return {questions};
};

export {useQuestionGenerator};