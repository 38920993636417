import React, {useRef} from 'react'
import { Link } from 'react-router-dom'
import {Collapse} from 'bootstrap'

const NavBar = () => {

  const menu = useRef();

  function hideMenu(){
    if(menu.current.classList.contains("show")){
      Collapse.getInstance(menu.current).hide();
    }
  }

  return (
    /*
    //  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"
    The data-bs-target attribute is what prevents the LINK elements from working
    */

    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <i className="bi bi-calculator" style={{color:"red"}}></i>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" ref={menu} id="navbarCollapse">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
              <Link className="nav-link" onClick={hideMenu} to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" onClick={hideMenu} to="/multiplication">Multiplication</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" onClick={hideMenu} to="/division">Division</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavBar