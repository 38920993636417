import React from 'react'
import {Outlet} from 'react-router-dom'
import NavBar from '../components/NavBar'

export const Layout = () => {
  return (
    <>
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/multiplication">Multiplication</Link>
          </li>
          <li>
            <Link to="/division">Division</Link>
          </li>
        </ul>
      </nav> */}
      <NavBar />
      <div className='container-lg'>
        <Outlet />
      </div>
      
    </>
  )
}

