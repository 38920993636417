
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout, Multiplication, SamplePage, Division, Home } from './pages/';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/multiplication" element={<Multiplication title="MULTIPLICATION" />} />
          <Route path="/division" element={<Division title="DIVISION" />} />
          <Route path="*" element={<SamplePage title="404" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
