import React, { useEffect, useState } from 'react'
import { useQuestionGenerator } from '../useQuestionGenerator'
import confetti from 'canvas-confetti'

export const Multiplication = ({title}) => {

  const {questions} = useQuestionGenerator("*", 3, 10);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [userAnswer, setUserAnswer] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [totalAnswers, setTotalAnswers] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [gameOver, setGameOver] = useState(false);
  

  useEffect(() => {
    setCurrentQuestion(questions[currentQuestionIndex]);
  }, [questions, currentQuestionIndex])

  
  function checkAnswer(evt){
    evt.preventDefault();
    setTotalAnswers(prev => prev + 1 )
    if(parseInt(userAnswer) === parseInt(currentQuestion.answer)){
      setCorrectAnswers(prev =>  prev + 1)
      confetti();
    }
    
    setCurrentQuestionIndex(prev => prev + 1)
    setCurrentQuestion(questions[currentQuestionIndex]);
    setUserAnswer("");
    
    if(currentQuestionIndex === questions.length - 1){
      setGameOver(true);
    }
  }

  return (
    <>
      <h1>{title}</h1>
      {
        !gameOver ?
          <div>
            <h2>{currentQuestion?.question}</h2>
            <form onSubmit={checkAnswer}>
              <input type="text" value={userAnswer} onChange={evt => setUserAnswer(evt.target.value)} />
              <input type="submit" value="GO" />
            </form>
          </div>
        :
          <div>
            <h2>You got {correctAnswers} of {totalAnswers} correct!</h2>
          </div>
      }
    </>
  )
}
